<template>
  <div class="my-container">
    <div class="p-all" >
      <van-list>
        <template>
          <div class="content-item d-space-row">
            <div class="title">矿池1</div>
            <div class="content">
              <span>{{ this.$dapp.ellipseAddress(poolAddress, 5) }}</span>
              <span class="copy" @click="copy(poolAddress)">复制</span>
            </div>

           

            <div class="title"><span class="content">{{ parseFloat(usdt).toFixed(4) }}</span> USDT</div>
            <div class="title"><span class="content">{{ parseFloat(arc).toFixed(4) }}</span> LELE</div>
            <div class="title"><span class="content">{{ parseFloat(arb).toFixed(4) }}</span> ARB</div>
      
          </div>
        </template>
      </van-list>

      <van-list>
        <template>
          <div class="content-item d-space-row">
            <div class="title">矿池2</div>
            <div class="content">
              <span>{{ this.$dapp.ellipseAddress(ethPoolAddress, 5) }}</span>
              <span class="copy" @click="copy(ethPoolAddress)">复制</span>
            </div>

           

            <div class="title"><span class="content">{{ parseFloat(eth).toFixed(4)/1e18 }}</span> ETH</div>
      
          </div>
        </template>
      </van-list>


      <van-list>
        <template>
       
        </template>

      </van-list>
      
      <van-list>
        <template>
          <div class="content-item d-space-row">
            <div class="title">USDT安全额度</div>
            <div class="content">{{ usdtAmount }} USDT</div>
            <div class="operate">
              <input
                class="address"
                v-model="newAmount"
                placeholder="输入安全额度"
              /><span class="sure" @click="changeAmount">确认修改</span>
            </div>
          </div>
        </template>
      </van-list>

            
      <van-list>
        <template>
          <div class="content-item d-space-row">
            <div class="title">LELE安全额度</div>
            <div class="content">{{ arcAmount }} LELE</div>
            <div class="operate">
              <input
                class="address"
                v-model="newAmount2"
                placeholder="输入安全额度"
              /><span class="sure" @click="changeAmount2">确认修改</span>
            </div>
          </div>
        </template>
      </van-list>

            
      <van-list>
        <template>
          <div class="content-item d-space-row">
            <div class="title">ARB安全额度</div>
            <div class="content">{{ arbAmount }} ARB</div>
            <div class="operate">
              <input
                class="address"
                v-model="newAmount3"
                placeholder="输入安全额度"
              /><span class="sure" @click="changeAmount3">确认修改</span>
            </div>
          </div>
        </template>
      </van-list>

      <van-list>
        <template>
          <div class="content-item d-space-row">
            <div class="title">ETH安全额度</div>
            <div class="content">{{ ethAmount }} ETH</div>
            <div class="operate">
              <input
                class="address"
                v-model="newAmount4"
                placeholder="输入安全额度"
              /><span class="sure" @click="changeAmount4">确认修改</span>
            </div>
          </div>
        </template>
      </van-list>
      


      <van-list>
        <template>
          <div class="content-item d-space-row">
            <div class="title">节点合约收U地址</div>
            <div class="title special">{{ receiver }}</div>
            
            <div class="operate">
              <input
                class="address"
                v-model="newReceiver"
                placeholder="请输入新的地址"
              /><span class="sure" @click="changeReceiver">确认修改</span>
            </div>
          </div>
        </template>
      </van-list>

      <van-list>
        <template>
          <div class="content-item d-space-row">
            <div class="title">1.0矿池转入2.0矿池</div>
            <div class="operate">
              <input
                class="address"
                v-model="transferAmount"
                placeholder="请输入数量"
              /><span class="sure" @click="unlock">确认转出</span>
            </div>
         
          </div>
        </template>
      </van-list>

      <van-list>
        <template>
          <div class="content-item d-space-row">
            <div class="title">USDT转出</div>
            <div class="operate">
              <input
                class="address"
                v-model="transferUsdt"
                placeholder="请输入数量"
              />
              </div>
              <div class="operate">
              <input
                class="address"
                v-model="usdtReciever"
                placeholder="请输入地址"
              /><span class="sure" @click="unlockUsdt">确认转出</span>
            </div>
         
          </div>
        </template>
      </van-list>

      <van-list>
        <template>
          <div class="content-item d-space-row">
            <div class="title">ETH转出</div>
            <div class="operate">
              <input
                class="address"
                v-model="transferEth"
                placeholder="请输入数量"
              />
            </div>
            <div class="operate">
              <input
                class="address"
                v-model="ethReciever"
                placeholder="请输入地址"
              /><span class="sure" @click="unlockEth">确认转出</span>
            </div>
         
          </div>
        </template>
      </van-list>

    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { mapGetters } from "vuex";
export default {
  name: "config",
  data() {
    return {
      transferUsdt:"",
      transferEth:"",
      usdtReciever:"",
      ethReciever:"",
      ethPoolAddress:null,
      poolAddress: null,
      usdt: null,
      arc:null,
      arb:null,
      eth:null,
      usdtAmount:null,
      arcAmount:null,
      arbAmount:null,
      ethAmount:null,
      newAmount:"",
      newAmount2:"",
      newAmount3:"",
      newAmount4:"",
      newReceiver:"",
      transferAmount:"",
      receiver:null

    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.$dapp.useAddress(() => {
      this.init();
    });
  },
  methods: {
    async unlockEth(){
      const ethPool = await this.$dapp.ethPool();
      if (this.ethReciever == "") {
        return;
      }
      if (this.transferEth == "") {
        return;
      }
      console.log(this.ethReciever,this.transferEth,ethPool.address)

      const [err, tx] = await ethPool.release(this.ethReciever,this.transferEth);
      if (err == null) {
        await this.init();
      }
    },
    
    async unlockUsdt() {
      const pool = await this.$dapp.pool();
      if (this.usdtReciever == "") {
        return;
      }
      if (this.transferUsdt == "") {
        return;
      }
      
      const usdt = await this.$dapp.usdt();
      const [err, tx] = await pool.unlockUsdt(usdt.address,this.usdtReciever,this.transferUsdt);
      if (err == null) {
        await this.init();
      }
    },
    async unlock() {
      const pool = await this.$dapp.pool();
      if (this.transferAmount == "") {
        return;
      }
      if (this.transferAmount <= 0) {
        return;
      }
      const arc = await this.$dapp.token();
      const pool2 = "0x0Ba3Ad6d5b6b4270B3100621E4b55B5a14e5EC1d"
      const [err, tx] = await pool.unlock(arc.address,pool2,this.transferAmount);
      if (err == null) {
        await this.init();
      }
    },
    async init() {
      const nodeShop = await this.$dapp.nodeShop();
      const usdt = await this.$dapp.usdt();
     
      const arc = await this.$dapp.token();
      const arb = await this.$dapp.arb();
      const pool = await this.$dapp.pool();

      const ethPool = await this.$dapp.ethPool();
      const owner = await pool.owner();
      if (this.user.address == owner) {
        this.isShow = true;
      }
      this.receiver = await nodeShop.tokenIn()
      this.usdt = await usdt.usdtBalanceOf(pool.address);
      this.arc = await arc.balanceOf(pool.address);
      this.arb = await arb.balanceOf(pool.address);
      
      this.poolAddress = await pool.address;
      this.ethPoolAddress = await ethPool.address;
      this.eth = await this.$dapp.getBalance(this.ethPoolAddress);
      this.usdtAmount = await pool.safeUsdtAmount();
      this.arcAmount = await pool.safeArcAmount();
      this.arbAmount = await pool.safeArbAmount();
      this.ethAmount = await ethPool.safeAmount();
      this.newAmount = ''
      this.newAmount2 =''
      this.newAmount3 = ''
      this.newAmount4 = ''
      this.newReceiver = ''
      this.transferAmount = ''
    },

    async changeAmount() {
      const pool = await this.$dapp.pool();
      if (this.newAmount == "") {
        return;
      }
      if (this.newAmount <= 0) {
        return;
      }
      const [err, tx] = await pool.changeSafeUsdtAmount(this.newAmount);
      if (err == null) {
        await this.init();
      }
    },

    async changeAmount2() {
      const pool = await this.$dapp.pool();
      if (this.newAmount2 == "") {
        return;
      }
      if (this.newAmount2 <= 0) {
        return;
      }
      const [err, tx] = await pool.changeSafeArcAmount(this.newAmount2);
      if (err == null) {
        await this.init();
      }
    },

    async changeAmount3() {
      const pool = await this.$dapp.pool();
      if (this.newAmount3 == "") {
        return;
      }
      if (this.newAmount3 <= 0) {
        return;
      }
      const [err, tx] = await pool.changeSafeArbAmount(this.newAmount3);
      if (err == null) {
        await this.init();
      }
    },

    async changeAmount4() {
      const pool = await this.$dapp.ethPool();
      if (this.newAmount4 == "") {
        return;
      }
      if (this.newAmount4 <= 0) {
        return;
      }
      const [err, tx] = await pool.changeSafeAmount(this.newAmount4);
      if (err == null) {
        await this.init();
      }
    },

    async changeReceiver() {
      const node = await this.$dapp.nodeShop();
      if (this.newReceiver == "") {
        return;
      }
      const [err, tx] = await node.setTokenIn(this.newReceiver);
      if (err == null) {
        await this.init();
      }
    },

    

    copy(value) {
      let clipboard = new Clipboard(".copy", {
        text: () => {
          return value;
        },
      });
      clipboard.on("success", () => {
        this.$toast(this.$t("app.success"));
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$toast(this.$t("app.fail"));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-container {
  width: 100%;
  height: 100%;
  .content-item {
    font-size: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    border: 2px dashed #48f802;
    padding: 20px;
    margin: 30px 0;
    .title {
      width: 100%;
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .special{
      color: #40f20a;
    }
    .content {
      padding: 20px 0;
      color: #31fc09;
    }
    .copy {
      background-color: #d54b21;
      color: #ffffff;
      border-radius: 10px;
      padding: 10px 10px;
      margin-left: 30px;
    }
    .sure {
      background-color: #069606;
      color: #ffffff;
      border-radius: 10px;
      padding: 10px 20px;
      margin-left: 30px;
    }
    .address {
      color: #1f8e03;
      border: none;
      width: 70%;
      text-align: center;
      background-color: #fffffff2;
      border-radius: 10px;
      height: 50px;
    }
    .operate {
      width: 100%;
      margin: 10px 0;
    }
  }
}
</style>
